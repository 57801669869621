import axios from '../marketplace/axios-marketplace';

const getIndicadoresCompras = () => axios.get('/compras/indicadores/situacoes', {});
const getSavings = params => axios.get('/compras/indicadores/savings', { params });
const getCompras = params => axios.get('/compras/indicadores/totais', { params });

export default {
  getSavings,
  getCompras,
  getIndicadoresCompras,
};
