<template>
  <v-card
    class="mx-auto rounded-card"
    v-bind="$attrs"
  >
    <v-card-text class="text--primary">
      <figure>
        <v-chart :options="chart"
                 ref="savingChart"
                 theme="primary"
                 autoresize/>
      </figure>
      <v-row dense class="text-center headline font-weight-bold">
        <v-col cols="12">
          {{ total | currency }}
        </v-col>
      </v-row>
      <v-row dense class="text-center">
        <v-col cols="12">
          últimos 12 meses
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import chartMixin from '@/mixins/chart-mixin';
import { buildMonthChart, toEchartData, sumData } from '@/utils/chart-utils';
import IndicadoresService from './dashboard-service';


export default {
  data() {
    return {
      savings: null,
      chart: null,
      total: 0,
    };
  },
  mixins: [chartMixin],
  methods: {
    showLoading() {
      this.$refs.savingChart.showLoading(this.loadingConfig);
    },
    hideLoading() {
      this.$refs.savingChart.hideLoading();
    },
    buildChart() {
      const savings = buildMonthChart(this.savings);
      this.total = sumData(this.savings, 'value');
      return {
        title: {
          text: 'Savings (mensal)',
        },
        tooltip: {
          formatter: this.tooltipFormatter,
        },
        dataset: {
          source: [
            ['Saving'],
            ...toEchartData(savings, 'description', 'value'),
          ],
        },
        xAxis: {
          type: 'category', interval: 1, maxInterval: 1, splitNumber: 1,
        },
        yAxis: {
          axisLabel: {
            formatter: this.axisMoneyFormatter,
          },
        },
        grid: {
          left: '15%',
        },
        series: [{ type: 'bar' }],
      };
    },
    getSavings() {
      const dataInicial = this.getDataInicial();
      const filter = `createdIn >= ${dataInicial.format('YYYY-MM-DDT00:00:00')}`;

      this.showLoading();
      IndicadoresService.getSavings({ filter })
        .then((response) => {
          this.savings = response.data;
          this.chart = this.buildChart();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
  mounted() {
    this.getSavings();
  },
};
</script>
